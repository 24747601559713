const fonctionOptions = [
  {
    text: "Distribution des médicaments",
    id: "1",
    value: "distribution des médicaments",
  },
  {
    text: "Transport des vacanciers",
    id: "2",
    value: "transport des vacanciers",
  },
  { text: "Restauration", id: "3", value: "restauration" },
  { text: "Entretien des locaux", id: "4", value: "entretien des locaux" },
  { text: "Activités spécifiques", id: "5", value: "activités spécifiques" },
  { text: "Autre", id: "6", value: "autre" },
];

export default {
  fonctionOptions,
};
